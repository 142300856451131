/**
 * Colors to use when rendering without any thresholds/overrides
 */

export const DEFAULT_OK_COLOR_RGBA = 'rgba(154, 205, 50)'; // #299c46

export const DEFAULT_OK_COLOR_SELECTED_RGBA = 'rgba(41, 156, 70, 0.9)';

export const DEFAULT_NO_DATA_COLOR_RGBA = 'rgba(154, 205, 50)';

export const DEFAULT_NO_DATA_COLOR_SELECTED_RGBA = 'rgba(41, 156, 70, 0.9)';
export const DEFAULT_NUMS_COLOR = 'rgb(237, 71, 59)'

export const FEATURE_EDIT_HANDLE_COLOR = 'rgb(255, 0, 255, 0.8)'
export const DEFAULT_EDIT_HANDLE_COLOR = 'rgb(224, 52, 184, 0.8)'

export const DEFAULT_CLUSTER_BK_COLOR = 'rgb(230,230,230)'
export const DEFAULT_LINE_WIDTH = 1
export const DEFAULT_SEL_LINE_WIDTH = 4
export const SEL_LINE_WIDTH_MULTIPLIER = 3

export const DEFAULT_COLOR_LABEL = 'no label'
